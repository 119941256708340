<template>
  <v-card class="white text-center">
    <v-card-title
      class="justify-center text--secondary font-weight-regular text-uppercase"
    >
      Nombres d'inscrits par département
    </v-card-title>

    <v-card-text>
      <!--v-skeleton-loader-- v-skeleton-loader-- type="image, table-row-divider@5"> </!--v-skeleton-loader-->
      <v-img height="40vh" width="100%" :aspect-ratio="4 / 3" class="bordered">
        <map-chart :geojson="geojson"></map-chart>
      </v-img>
    </v-card-text>
    <v-card-text>
      <list-of-user-by-dep
        class="pa-2"
        :data="depListItems"
      ></list-of-user-by-dep>
    </v-card-text>
  </v-card>
</template>

<script>
import UserService from '../../../services/user.service'

export default {
  name: 'DepCard',

  components: {
    'map-chart': () => import('../../../core/graphs/MapChart'),
    'list-of-user-by-dep': () => import('./ListOfUserByDep')
  },

  data: () => ({
    countPostal: [],
    geojson: null,
    loading: false,
    depListItems: []
  }),

  created() {
    this.loading = true

    fetch(
      // Adaptation de 'https://rawgit.com/gregoiredavid/france-geojson/master/departements.geojson'
      '/geojson/departements.geojson'
    )
      .then(response => {
        console.log(response)
        return response.json()
      })
      .then(json => {
        // Attention pour la Corse : [0]>2A [20]>2B
        this.data = json
        this.getPostal()
      })
  },

  methods: {
    getPostal() {
      // Récupération du count par département
      UserService.countPostal().then(
        response => {
          this.countPostal = response.data
          this.countPostal.forEach(item => {
            // Remplissage du geojson avec des valeurs
            this.data.features[parseInt(item._id)].properties.value = item.count

            // Création d'un tableau pour la liste
            this.depListItems.push({
              num: item._id,
              dep: this.data.features[parseInt(item._id)].properties.nom,
              count: item.count
            })
          })
          this.geojson = this.data
          this.loading = false
        },
        error => {
          console.error(error.response)
        }
      )
    }
  }
}
</script>

<style scoped>
.bordered {
  border: 1px solid gray;
}
</style>
